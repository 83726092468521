// // Loader.js
// import React from "react";
// import { ClipLoader } from "react-spinners";
// import { css } from "@emotion/react";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

// const Loader = ({ loading }) => (
//   <div className="loader">
//     <ClipLoader color={"#123abc"} loading={loading} css={override} size={50} />
//   </div>
// );

// export default Loader;
import React from "react";
import { Spinner, Modal } from "react-bootstrap";

const Loader = ({ loading }) => {
  return (
    <Modal show={loading} centered backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status"></Spinner>
        <p
          style={{
            color: "black",
            fontSize: 21,
            marginLeft: 10,
            marginTop: 10,
            fontFamily: "sans-serif",
          }}
        >
          Loading...
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
