import axios from "axios";
import * as yup from "yup";
import * as formik from "formik";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { Container, Row, Col, Card, Dropdown } from "react-bootstrap";

import "../styles.css";
import "./apbStyles.css";
import { X_API_KEY } from "../../../constants";
import Loader from "../../../components/Loader";
import { FcCamcorderPro } from "react-icons/fc";
import PointsIcon from "../../../assets/Apb/PointIcon.png";
import UploadGuideVideo from "../../../assets/Apb/UploadInstructionsVideo.mp4";

const ScreenReplacementForm = () => {
  const navigate = useNavigate();

  const { Formik } = formik;
  const mediaStreamRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const mediaRecorderRef = useRef(null);

  const [videoSrc, setVideoSrc] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [videoRecInBase64, setVideoRecInBase64] = useState(""); // Video
  const [apbModel, setApbModel] = useState("");
  const [loading, setLoading] = useState(false);
  const [apbBrands, setApbBrands] = useState("");
  const [bppsTaxId, setBppsTaxId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bppsTaxIdList, setBppsTaxIdList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Select an option");

  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
  };

  const initialValues = {
    searchQuery: mobileNumber,
    name: "",
    email: "",
    brand: apbBrands,
    model: apbModel,
    imeiNumber: "",
    invoicePhoto: "",
    devicePhoto: "",
  };
  const schema = yup.object().shape({
    searchQuery: yup
      .string()
      .required("Mobile number is required to search")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    // vpsTaxId: yup.string().required("Please select an option"),
    name: yup
      .string()
      .matches(/^[A-Za-z ]+$/, "Name should only contain letters and 1 space")
      .min(2, "Name should be at least 2 characters long")
      .max(60, "Name should not exceed 60 characters")
      .required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    imeiNumber: yup
      .string()
      .matches(/^[0-9]{15}$/, "Invalid IMEI number")
      .required("IMEI number is required"),
  });

  // *********** Search BppsTxId By Mobile Api ***********
  const searchTByMobileApi = (searchQuery) => {
    axios(
      `https://portal.acrossassist.com/api/partner/v1/apb/GetBppsTaxIdByMobileNumber?CustomerMobileNumber=${searchQuery}`,
      {
        method: "GET",
        headers: { "x-api-key": X_API_KEY, "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setBppsTaxIdList(res?.data?.data);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.msg);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((e) => {});
  };

  // *********** Get Brand & Model By BppsTxId Api ***********
  const getBrandAndModelByBppsTaxIdApi = (refId) => {
    const DATA = { BBPSTaxRefId: refId };

    axios(
      "https://portal.acrossassist.com/api/partner/v1/apb/GetBrandAndModelByBppsTaxId",
      {
        method: "POST",
        data: DATA,
        headers: {
          "x-api-key": X_API_KEY,
        },
      }
    )
      .then((res) => {
        console.log("137 ==== bbps res ====", res);
        if (res?.data?.statusCode === 200) {
          setApbBrands(res?.data?.data?.brands);
          setApbModel(res?.data?.data?.model);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.msg);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((e) => {});
  };

  // *********** Start Video Recording ***********
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        // video: true
        video: { facingMode: "environment" }, // Use the back camera
      });
      mediaRecorderRef.current = new MediaRecorder(stream);

      // Collect recorded data
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      // Handle stop recording
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: "video/mp4" });
        recordedChunksRef.current = []; // Reset chunks for next recording

        // Create a video URL for playback
        const videoURL = URL.createObjectURL(blob);
        setVideoSrc(videoURL); // Set the video URL to state

        // Convert blob to base64
        convertToBase64(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing user media:", error);
    }
  };

  // *********** Stop Video Recording ***********
  const stopRecordingOld = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
  };
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }

    // Stop the camera stream
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    setIsRecording(false);
  };

  // *********** Convert Video Blob to base64 ***********
  const convertToBase64 = (blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1]; // Extract only base64 string
      setVideoRecInBase64(base64String); // Set base64 video data to state
    };
    reader.readAsDataURL(blob);
  };

  // *********** Upload Validation ***********
  const handleSubmit = (values) => {
    if (videoRecInBase64 === "") {
      toast.error("Please record a screen video to upload.");
    } else {
      uploadDetailsApi(values);
    }
  };

  // *********** Upload Form Api ***********
  const uploadDetailsApi = async (values) => {
    setLoading(true);

    const DATA = {
      Email: values?.email,
      BrandName: values?.brand,
      ModelName: values?.model,
      UserName: values?.name,
      MobileNumber: values?.searchQuery,
      DeviceImage: videoRecInBase64, // Base64 Video Upload
      imeiOne: values?.imeiNumber,
      PaymentRequestId: bppsTaxId,
    };
    console.log("1019 ==== Api Data ====", DATA);

    axios(
      "https://portal.acrossassist.com/api/partner/v1/apb/SaveAirtelPaymentBankDetailsVideo", // Video Upload Url
      {
        method: "POST",
        data: DATA,
        headers: {
          "x-api-key": X_API_KEY,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setVideoRecInBase64("");
          navigate("/statusPage");
          setLoading(false);
        } else {
          toast.error("Please validate your form and Try again !");
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        toast.error("Form not submitted. Please try again");
        setLoading(false);
      });
  };

  return (
    <Container
      fluid
      className="bg-light py-0 px-0"
      style={{ minHeight: "100vh" }}
    >
      <Card className="border-0">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Card.Body>
            {/* ****** Header Container ****** */}
            <Row className="d-flex justify-content-between align-items-center px-0 mb-4">
              <Col
                // xs={12}
                xs={6}
                md={6}
                className="d-flex justify-content-start align-items-center"
              >
                <img
                  alt="Apb Logo"
                  className="img-fluid apb-logo"
                  src={require("../../../assets/Apb/ApbLogo.png")}
                />
              </Col>
              <Col
                xs={6}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <img
                  alt="Across Logo"
                  className="img-fluid apb-logo"
                  src={require("../../../assets/Apb/AcrossLogo.png")}
                />
              </Col>
            </Row>

            {/* ****** Images Container ****** */}
            <Row className="mt-4">
              <Col xs={12} className="activate-plan-img">
                <img
                  alt="Activate Plan"
                  className="img-fluid"
                  style={{ width: "100%", objectFit: "contain" }}
                  src={require("../../../assets/Apb/ActivatePlan.png")}
                />
              </Col>
            </Row>

            {/* ****** Form Container ****** */}
            <Formik
              validator={() => ({})}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={schema}
              initialValues={initialValues}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="p-2">
                  {/* *********** Heading Container *********** */}
                  <p className="text-danger mt-5 text-left input-label-heading">
                    *Ensure the name matches the device owner name
                  </p>

                  {/* *********** Mobile Search & Dropdown Container *********** */}
                  <Row>
                    {/* ****** Mobile Search Input ****** */}
                    {/* <Col xs={12} sm={6} md={6} lg={6} className="mb-3 mb-md-0"> */}
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="mb-3 py-1 px-4"
                    >
                      <Form.Group controlId="validationFormikUsername">
                        <Form.Label className="common-label">Mobile</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            name="searchQuery"
                            value={mobileNumber}
                            className="common-input"
                            isInvalid={!!errors.searchQuery}
                            aria-describedby="inputGroupPrepend"
                            placeholder="Search by mobile number"
                            onChange={(e) => {
                              handleChange(e);
                              setMobileNumber(e?.target.value);
                              searchTByMobileApi(e?.target.value);
                            }}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="common-feedback"
                          >
                            {errors.searchQuery}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    {/* ****** Dropdown Input ****** */}
                    <Col xs={12} md={6} className="mb-3 py-1 px-4">
                      <Form.Group controlId="validationFormikDropdown">
                        <Form.Label className="common-label">
                          BBPS Tax ID
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Dropdown
                            data-bs-theme="dark"
                            onSelect={(e) => handleSelect(e)}
                          >
                            <Dropdown.Toggle
                              variant=""
                              className="drop-down-input"
                              id="dropdown-button-dark-example1"
                            >
                              {selectedValue
                                ? selectedValue
                                : "Select an option"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="drop-down-menu">
                              {bppsTaxIdList?.map((item) => (
                                <Dropdown.Item
                                  onClick={() => {
                                    getBrandAndModelByBppsTaxIdApi(
                                      item?.bbpsTaxRefId
                                    );
                                    setBppsTaxId(item?.id);
                                  }}
                                  eventKey={item?.bbpsTaxRefId}
                                >
                                  {item?.bbpsTaxRefId}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>

                          <formik.ErrorMessage
                            name="dropdownField"
                            component="div"
                            className="text-danger mt-2"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* *********** Name & Email Container *********** */}
                  {/* <Row className="mb-3 py-1 px-4"> */}
                  <Row>
                    {/* ****** Name Input ****** */}
                    <Col xs={12} md={6} className="mb-3 py-1 px-4">
                      <Form.Group controlId="validationFormikUsername">
                        <Form.Label className="common-label">Name</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            onChange={handleChange}
                            className="common-input"
                            isInvalid={!!errors.name}
                            aria-describedby="inputGroupPrepend"
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="common-feedback"
                          >
                            {errors.name}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    {/* ****** Email Input ****** */}
                    <Col xs={12} md={6} className="mb-3 py-1 px-4">
                      <Form.Group controlId="formGridEmail">
                        <Form.Label className="common-label">Email</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            className="common-input"
                            isInvalid={!!errors.email}
                            // If Width is more then remove width and make New Styles for this.
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="common-feedback"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* *********** Brand & Model Container *********** */}
                  <Row>
                    {/* ****** Brand Input ****** */}
                    <Col xs={12} md={6} className="mb-3 py-1 px-4">
                      <Form.Group controlId="validationFormik02">
                        <Form.Label className="common-label">Brand</Form.Label>
                        <Form.Control
                          readOnly
                          type="text"
                          name="brand"
                          value={apbBrands}
                          className="common-input"
                        />
                      </Form.Group>
                    </Col>

                    {/* ****** Model Input ****** */}
                    <Col xs={12} md={6} className="mb-3 py-1 px-4">
                      <Form.Group>
                        <Form.Label className="common-label">Model</Form.Label>
                        <Form.Control
                          readOnly
                          type="text"
                          name="model"
                          value={apbModel}
                          placeholder="Model"
                          className="common-input"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* *********** IMEI 1 Container *********** */}
                  <Row>
                    {/* <Col xs={12} sm={6} md={6} lg={6} className="mb-3 mb-md-0"> */}
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="mb-3 py-1 px-4"
                    >
                      <Form.Group controlId="validationFormikUsername">
                        <Form.Label className="common-label">IMEI 1</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            name="imeiNumber"
                            onChange={handleChange}
                            className="common-input"
                            value={values.imeiNumber}
                            placeholder="Enter IMEI1 number"
                            isInvalid={!!errors.searchQuery}
                            aria-describedby="inputGroupPrepend"
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="common-feedback"
                          >
                            {errors.searchQuery}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* *********** Upload Gradient Heading Container *********** */}
                  <div>
                    <img
                      className="img-fluid"
                      alt="Responsive Example"
                      src={require("../../../assets/Apb/UploadVideoBar.png")}
                    />
                  </div>

                  {/* *********** Upload Instructions Container *********** */}
                  <div className="mt-3">
                    <div className="instructions-container border-0 rounded p-1">
                      {/* ****** Instructions 1 ****** */}
                      <div className="instruction-item d-flex align-items-start">
                        <img
                          src={PointsIcon}
                          alt="Points Icon"
                          className="intructions-point-icon"
                        />
                        <p>
                          Open the provided link on a device other than the one
                          you wish to protect. <br />
                          <span className="text-muted">
                            प्रदान किए गए लिंक को उस डिवाइस के अलावा किसी अन्य
                            डिवाइस पर खोलें जिसे आप सुरक्षित करना चाहते हैं।
                          </span>
                        </p>
                      </div>

                      {/* ****** Instructions 2 ****** */}
                      <div className="instruction-item d-flex align-items-start">
                        <img
                          src={PointsIcon}
                          alt="Points Icon"
                          className="intructions-point-icon"
                        />
                        <p className="mb-1">
                          Fill in the required fields and then select the
                          “device video upload” option. <br />
                          <span className="text-muted">
                            आवश्यक फील्ड भरें और फिर "डिवाइस वीडियो अपलोड"
                            विकल्प चुनें।
                          </span>
                        </p>
                      </div>

                      {/* ****** Instructions 3 ****** */}
                      <div className="instruction-item d-flex align-items-start">
                        <img
                          src={PointsIcon}
                          alt="Points Icon"
                          className="intructions-point-icon"
                        />
                        <p className="mb-0">
                          Use another mobile device to record a clear video of
                          the protected device's screen as you dial *#06#,
                          showing the IMEI details. Please note that screenshots
                          and images will not be accepted. <br />
                          <span className="text-muted">
                            कृपया दूसरे मोबाइल डिवाइस का उपयोग करके संरक्षित
                            डिवाइस की स्क्रीन का स्पष्ट वीडियो रिकॉर्ड करें,
                            जिसमें *#06# डायल करते समय IMEI विवरण दिखाएं। कृपया
                            ध्यान दें कि स्क्रीनशॉट और छवियां स्वीकार नहीं की
                            जाएंगी।
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* *********** Guide & Upload Video Container *********** */}
                  <div className="container-fluid mt-3 d-flex flex-column align-items-center text-center video-main-container">
                    <div className="container-fluid text-center video-sub-main-container">
                      <div className="row g-4 align-items-center">
                        {/* *********** How To Upload Video Container *********** */}
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          {/* *********** Heading Container *********** */}
                          <div className="mt-2">
                            <p className="text-primary fw-bold font-size-vw">
                              How To Upload
                            </p>
                          </div>

                          {/* *********** Video Container *********** */}
                          <video
                            loop
                            muted
                            autoPlay
                            src={UploadGuideVideo}
                            className="upload-guide-video"
                            onContextMenu={(e) => e.preventDefault()} // Disable right-click
                          />
                        </div>

                        {/* *********** Record Video Container *********** */}
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          {/* *********** Heading Container *********** */}
                          <div className="mt-4">
                            <p className="text-primary fw-bold font-size-vw">
                              Upload/Preview Video
                            </p>
                          </div>

                          {/* *********** Video or Card Container *********** */}
                          {videoRecInBase64 ? (
                            <video
                              controls
                              className="upload-guide-video"
                              src={`data:video/mp4;base64,${videoRecInBase64}`} // Base64 encoded video
                            />
                          ) : (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="card d-flex justify-content-center align-items-center upload-card-container">
                                  <div>
                                    <FcCamcorderPro size={30} />
                                  </div>
                                  <h5 className="text-secondary mt-1">
                                    Record and upload your videos.
                                  </h5>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* *********** Recording Button *********** */}
                    <div className="container-fluid d-flex justify-content-end mt-1 mb-3">
                      {videoRecInBase64 ? (
                        <button
                          className="btn responsive-btn"
                          onClick={isRecording ? stopRecording : startRecording}
                        >
                          Re-Record Video
                        </button>
                      ) : (
                        <button
                          className="btn responsive-btn"
                          onClick={isRecording ? stopRecording : startRecording}
                        >
                          {isRecording
                            ? "Stop Video Recording"
                            : "Record Video"}
                        </button>
                      )}
                      <style jsx>{`
                        .responsive-btn {
                          background: linear-gradient(
                            90deg,
                            #98d5fa,
                            #1e6cfd,
                            #98d5fa
                          );
                          border: none;
                          color: white;
                          padding: 10px;
                          font-size: 16px;
                          cursor: pointer;
                          font-weight: bold;
                          border-radius: 5px;
                          font-family: "Poppins", serif;
                          transition: transform 0.2s, opacity 0.2s;
                          width: 46%; /* Default for lg and md screens */
                        }

                        @media (max-width: 991.98px) {
                          /* For sm and xs screens */
                          .responsive-btn {
                            width: 100%;
                          }
                        }
                      `}</style>
                    </div>
                  </div>

                  {/* *********** Button Container *********** */}
                  <div className="container-fluid d-flex justify-content-center w-100 mt-5">
                    <button className="btn responsive-btn">Submit</button>

                    <style jsx>{`
                      .responsive-btn {
                        background: linear-gradient(
                          90deg,
                          #98d5fa,
                          #1e6cfd,
                          #98d5fa
                        );
                        border: none;
                        color: white;
                        padding: 10px;
                        font-size: 16px;
                        cursor: pointer;
                        font-weight: bold;
                        border-radius: 5px;
                        font-family: "Poppins", serif;
                        transition: transform 0.2s, opacity 0.2s;
                        width: 50%; /* Default for lg and md screens */
                      }

                      @media (max-width: 991.98px) {
                        /* For sm and xs screens */
                        .responsive-btn {
                          width: 100%;
                        }
                      }
                    `}</style>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        )}
      </Card>
    </Container>
  );
};

export default ScreenReplacementForm;
// 1745 lines of code
