export const X_API_KEY = "f56f1011-7905-4a80-afbc-d69a5bcac1d5";

export const GENERATE_OTP_URL =
  "https://portal.acrossassist.com/api/partner/v1/apb/GenerateOTP?mobileNumber=";
export const RESEND_OTP_URL =
  "https://portal.acrossassist.com/api/partner/v1/apb/GenerateOTP?mobileNumber=";
export const VERIFY_OTP_URL =
  "https://portal.acrossassist.com/api/partner/v1/apb/VerifyOtp?mobileNumber=";
export const GET_CERTIFICATE_FOR_AIRTEL_URL =
  "https://portal.acrossassist.com/api/partner/v1/apb/GetCertificate?mobileNumber=";
export const VIEW_CERTIFICATE_URL =
  "https://portal.acrossassist.com/api/partner/v1/apb/GetCertificateForAirtel?Certificateid=";
